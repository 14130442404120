import { ArticleType, DocumentType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Espace/props';
import IconEye from '../../../icons/Eye';
import IconEyeSlash from '../../../icons/EyeSlash';
import ListItem from './index';

const ListItemArticle: FC<ListItemProps<ArticleType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<ArticleType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem displayActionId={false} doc={doc} index={index} onClick={onClick}>
      <div className="ml-3 flex items-center space-x-3">
        {!doc.hidden && <IconEye />}
        {doc.hidden && <IconEyeSlash />}

        <strong>{doc.name}</strong>
      </div>
    </ListItem>
  );
};

export default ListItemArticle;
