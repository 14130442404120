import { ArticleData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../../../../components/List/Item/Article';
import CMSView from '../../../../../components/View';
import List from '../../../../../containers/Espace/List';
import params from '../../../../../params/article/index.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const ArticlesPage: FC<PageProps & EspaceProps & UserProps> = ({
  location,
  espace,
  user,
  params: { channelId, espaceId },
}) => {
  const { t } = useTranslation();

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix={location.pathname}
          model={new ArticleData({ channelId, espaceId, params })}
          search={location.search}
          title={t('articles.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(ArticlesPage);
